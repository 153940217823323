

import UMUM from         '../../library/umum'
import FETCHING from         '../../library/fetching'

export default {
  data() {
    return {
      list_data : [],

      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,

      filterku : {
        unit_kerja_id : '',
        date : '',
        bulan : '',
        tahun : '',
        tglFull: '',
      },

      UMUM :UMUM,
      FETCHING : FETCHING,




      alert: false,
      simpan1: false,
      model :'',
      inputSelect : [
        {id : '1', nama : 'kiken', alamat : 'beringin'},
        {id : '2',nama : 'Alung', alamat : 'Anawai'},
      ]
    }
  },
  methods: {
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_presensi_lapHarian + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              date : this.filterku.date,
              bulan : this.filterku.bulan,
              tahun : this.filterku.tahun,
              unit_kerja_id : this.filterku.unit_kerja_id,
              data_ke: this.page_first,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
            this.$store.commit("hideLoading");
            this.list_data = res_data
              console.log(res_data);
      });
    },


    searchByDate(){
      var datex = this.filterku.tglFull

      var date = datex.split('/')

      // console.log(datex)

      this.filterku.tahun = date[0];
      this.filterku.bulan = parseInt(date[1]);
      this.filterku.date = date[2];
      this.getView()
    },





    filterUnitKerja : function (val, update, abort) {
        update(() => {
          if (val === '') {}
          else {FETCHING.postUnitKerjaAuto(val)}
        })
    },



    simulateProgress (number) {
      // we set loading state
      this[`simpan${number}`] = true
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        this[`simpan${number}`] = false
      }, 3000)
    }
  },


  mounted() {

    const d = new Date();

    this.filterku.tglFull = d.getFullYear()+'/'+(parseInt(d.getMonth())+1)+'/'+d.getDate();
    
    this.filterku.tahun = d.getFullYear();
    this.filterku.bulan = d.getMonth()+1;
    this.filterku.date = d.getDate()

    // console.log(tahun+"-"+bulan+"-"+date)




    this.filterku.unit_kerja_id = this.$store.state.unit_kerja
    this.FETCHING.postUnitKerjaAuto('', this.filterku.unit_kerja_id)
    this.getView()




  },
}
